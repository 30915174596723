*, *::after, *::before {
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
}

:root {
	--light-gray: hsla(0,0%,93%,1);
	--gray: hsla(0,0%,60%,1);
	--purple: hsl(265, 46%, 19%);
	--purple-theme: 265, 46%, 19%;
	--primary-light-hsl: 265, 46%, 80%;
	--primary-hsl: 265, 46%, 19%;
	--primary-dark-hsl: 265, 46%, 9%;
}

body {
	margin: 0;
	text-align: center;
}

