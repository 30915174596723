.bg-fullscreen {
	position: fixed;
	top: 0;
	background-size: cover;
    width: 100vw;
    height: 100vh;
    background-position: center;
}

.content {
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;
	min-height: 60vh;
}

.my-container{
	--color: hsl(var(--primary-dark-hsl), .8);
	background-color: var(--color);
	box-shadow: 0 0 15px 0 var(--color);
	padding: 40px 30px;
	border-radius: 20px;
	width: 40%;
	max-width: 600px;
	margin: 3rem 0;
}

.container-title {
	margin: 0;
	margin-bottom: 1rem;
	color: white;
	text-align: center;
	font-size: 2rem;
	font-weight: normal;
}

.intro {
	color: white;
	font-size: 1.25em;
	text-align: center;
}


@media only screen and (max-width: 880px) {
	.content {
	  flex-direction: column;
	}

	.my-container{
		padding: 30px 25px;
		width: 80%;
		max-width: 600px;
		margin: 2rem 0;
	}
}

@media only screen and (max-width: 440px) {
	.content {
	  flex-direction: column;
	}

	.my-container{
		padding: 25px 20px;
		width: 90%;
		max-width: 600px;
		margin: 1.75rem 0;
	}
}

.products-page {
    background-color: var(--purple);
    color: white;
    display: flex;
    margin: 1rem;
    padding: 1.5rem 0;
    min-height: 80vh;
    border-radius: 0.4em;
}

.products-card {
    color: black;
}

.products-card-img {
	max-height: 18rem;
	object-fit: contain;
}

.nav-tabs .nav-link {
	color: white;
}

.nav-tabs .nav-link:hover {
	color: white;
}

.form {
	display: flex;
	flex-direction: column;
	color: white;
	gap: 7px;
}

.input-group {
	display: flex;
	flex-direction: column;
	gap: 7px;
}

.input-group label {
	color: white;
	font-weight: lighter;
}

.input-group input,
.input-group textarea {
	font-size: 1.25rem;
	padding: 0.5rem;;
	border: none;
	outline: none;
	border-radius: 0.25rem;
	font-weight: lighter;
	background-color: hsl(var(--primary-light-hsl), .3);
	color: white;
}

.input-group input:focus,
.input-group textarea:focus {
	box-shadow: 0 0 0 1px white;
}

.login-button {
	padding: .5em 1em;
	font-size: 1.5rem;
	font-weight: lighter;
	color: white;
	background-color: hsl(var(--primary-hsl));
	border: 1px solid hsl(var(--primary-hsl));
	border-radius: .25em;
	outline: none;
	cursor: pointer;
	margin-top: 20px;
}


.login-button:hover {
	border: 1px solid white;
}

.footer {
	position: absolute;
	background-color: white;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.logo {
	height: 160px;
	margin: 2rem 2rem;
}

.social {
	margin: 1rem 2rem;
	display: flex;
	flex-direction: column;
	color: var(--purple);
}

.social-links {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.facebook {
	color: var(--purple);
}

.instagram {
	color: var(--purple);
}

.scroll-button {
    position: fixed;
    bottom: 0;
    right: 40%;
    transform: translate(-50%, -50%);
    color: var(--yellow);
    background-color: white;
    border-radius: 50%;
}


@media only screen and (max-width: 880px) {
	.logo { 
		height: 130px;
	}
}
